
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

//.modal-overlay {
//  position: fixed;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  overflow: hidden;
//  background-color: rgba(0, 0, 0, 0.6);
//  backdrop-filter: blur(5px);
//}

//.modal {
//  background: white;
//  padding: 20px;
//  border-radius: 10px;
//  text-align: center;
//}

.modal button {
  margin: 5px;
}
