.card {
  font-family: Montserrat, sans-serif;
  width: 345px;
  height: 472px;
  border-radius: 10px;
  background: #F0F0F0;
  position:relative;
  margin-left: 20px;
  text-align: center;
}
.card span {
  font-size: 12px;
  font-family: "Organetto", sans-serif;
  margin: 5px;
}

.card-image {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 6px;
}

.card-description {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-top: 6px;
}
.clamp {
  height: 152px;
}
.clamp p {
  width: 305px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  word-wrap: break-word;
  overflow: hidden
}

.card-button {
  width: 100%;
  height: 55px;
  padding: 15px 20px 15px 20px;
  gap: 30px;
  border-radius: 10px;
  background: #1A1A1A;
  border: none;
  color: white;
  cursor: pointer;
}

.card-button:hover {
  transform: scale(1.05) translateY(-2px);
}

.btn-icon {
  margin-left: 7%;
}

.btn-pinned {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1;
}

@media (max-width: 768px) {
  .card {
    width: 365px;
    margin-left: 0;
  }
  .card-button:active {
    background: #282727
  }
  .card-button:hover {
    transform: scale(1) translateY(0px)
  }
  .card p {
    width: 90%;
    margin-top: 15px;
  }
  .card-image {
    height: 300px;
  }
  .card-button {
    width: 100%;
    height: 52px;
  }
}
@media (max-width: 400px) {
  .card {
    width: 95%;
    margin-left: 0px;
  }
}
@media (max-width: 370px) {
  .card {
    width: 95%;
    margin-left: 0px;

  }
}
