.network-field {
  border: solid 1px #cccccc;
  border-radius: 5px;
  padding-bottom: 10px;
  margin: 10px;
}

.network-field svg {
  cursor: pointer;
  margin-left: 92%;
}

.partner-dropdown_menu select {
  cursor: pointer;
}