.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.mobile-search-container {
  position: absolute;
  top: 80px;
  width: 90%;
  height: 32px;
  margin: auto;
}

.mobile-search-input {
  height: 32px;
  padding: 6px 12px;
  margin-top: 1px;
  font-size: 16px;
  border-bottom: 1px solid #A7A7A7;
  font-family: "Montserrat", sans-serif;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
.mobile-search-input:focus-visible {
  border-bottom: 1px solid #A7A7A7;

  border-top: none;
  border-left: none;
  border-right: none;
}

.mobile-search-icon {
  position: absolute;
  top: 53%;
  right: 14px;
  transform: translateY(-50%);
  padding: 6px;
  cursor: pointer;
}


.mobile-search-results {
  position: absolute;
  top: 136%;
  left: 11px;
  right: 0;
  z-index: 1000;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  max-height: 200px;
  overflow-y: auto;
}

.mobile-search-result-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.mobile-search-result-item:last-child {
  border-bottom: none;
  color: #1A1A1A;
  text-decoration: none;
}

.mobile-no-results {
  padding: 10px;
  color: #999;
}

.mobile-search-link {
  text-decoration: none;
}

.mobile-highlighted {
  background-color: #eaeaea;
}