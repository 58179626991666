.admin-form-container {
  max-width: 600px;
  margin-top: 5%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.admin-form {
  display: flex;
  flex-direction: column;
}

.admin-form h2 {
  text-align: center;
  margin-bottom: 20px;
}
.admin-form input {
  width: 95%;
}
#isAdmin {
  width: 20%;
}
.admin-form input,
.admin-form button {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.admin-form input:focus,
.admin-form button:focus {
  border-color: #007bff;
  outline: none;
}
.admin-form {
  .checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    margin-left: 1%;

    input {
      display: none;
    }

    .custom-checkbox {
      position: relative;
      width: 18px; // Ширина и высота кастомного чекбокса
      height: 18px;
      border: 2px solid #ccc; // Граница кастомного чекбокса
      border-radius: 50px; // Скругление углов

      img {
        display: none; // Скрыть изображение по умолчанию
        width: 100%;
        height: 100%;
      }
    }

    input:checked + .custom-checkbox {
      background-color: transparent;
      border-color: transparent;
    }

    input:checked + .custom-checkbox img {
      display: block;
    }

    p {
      margin: 0;
      margin-left: 10px;
    }
  }
}

.admin-form button {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.admin-form button:hover {
  background-color: #0056b3;
}

.admins-list {
  margin-top: 30px;
}

.admins-list h2 {
  text-align: center;
  margin-bottom: 20px;
}

.admins-list ul {
  list-style: none;
  padding: 0;
}

.admin-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.admin-item form {
  display: contents;
  width: calc(100% - 30px);
  border-radius: 5px;
}

.admin-item button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
}

.admin-item button:hover {
  background-color: #c82333;
}


.success-message {
  background: #f45a5a;
  border-radius: 50px;
  text-align: center;
  line-height: 32px;
  margin-top: 1em;
  color: #F0F0F0;
  font-size: 12px;
  padding: 5px 20px 5px 20px;
}

.error-message {
  height: 32px;
  border-radius: 50px;
  text-align: center;
  line-height: 32px;
  margin-top: 0.5em;
  color: #F0F0F0;
  font-size: 12px;
}
@media (max-width: 768px) {
  .admin-item {
    height: 60px;
    font-size: 11px;
    margin-right: 0;
  }
  .admin-item input {
    height: 50px;
  }
  .admin-item button{
    margin-right: 0;
    font-family: "Montserrat", sans-serif;
    border-radius: 20px;
  }
  .admin_item-edit {
    display: flex;
    gap: 5px;
  }
}