.admin_page {
  font-family: Organetto, sans-serif;
  width: 78%;
  margin: auto;
  justify-content: center;
}

.admin_navbar {
  width: 100%;
  display: flex;
  justify-content: space-around;
}


.admin_cards {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  align-items: center;
  color: #494A3A;
}

.logout_btn {
  height: 32px;
  font-family: Organetto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;
  background: #FF8C8C;
  color: #1A1A1A;
  padding: 5px 10px 5px 10px;
  border: 1px solid #000000;
  cursor: pointer;
}

.logout_btn:hover {
  box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.5);
}

.admin_page {
  padding: 20px;
  margin-bottom: 30%;
}



.admin_cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card-container {
  flex: 1 1 calc(25% - 20px); /* Each card will take 25% of the row, minus the gap */
  box-sizing: border-box;
  margin-bottom: 20px;
}

.card-image {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
}

.btn_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.admin_btn {
  height: 38px;
  font-family: Organetto, sans-serif;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50px;
  background: #F0F0F0;
  color: #1A1A1A;
  padding: 5px 20px 5px 20px;
  border: 1px solid #000000;
  margin: 2%;
  cursor: pointer;
  background: none;
  outline: none;
}

.admin_btn:hover {
  box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.5);
}

.delete_btn {
  width: 30px;
}

.card_edit_btn {
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
  margin-left: 10px;
}

.admins-list {
  width: 60%;
  margin: auto;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
@media (max-width: 912px) {
  .admin_page {
    width: 100%;
  }
  .form_container form {
    width: 90%;
  }
  .admin_cards {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 40px;
  }
  .networks-section {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .admin_page {
    width: 100%;
    margin: 10px;
  }
  .logout_btn {
    height: 50px;
    margin-left: auto;
    margin-bottom: 30px;
  }
  .admin_navbar {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .admin_navbar h2{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
  .btn_container {
    flex-direction: column;
    align-items: center;
  }

  .admin_btn {
    width: 100%;
    height: 50px;
    margin: 5px 0;
  }
  .admin_btn:hover {
    box-shadow: none;
  }

  .admins-list {
    width: 100%;
  }
}

