.add-category_form {
  width: 100%;
  height: 300px;
  margin-top: 3%;
  border: 1px solid #ccc;
  border-radius: 4px ;
}

.add-category_container {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}

.add-category_input {
  display: grid;
  justify-content: center;
}

.add-category_input input {
  margin-left: 0;
}

@media (max-width: 768px) {
  .add-category_container {
    margin-top: 60px;
    margin-bottom: 40px;
    text-align: center;
  }
    .add-category_input input {
    width: 100%;
    height: 60px;
  }
  .add-category_input form {
    width: 80vw;
    text-align: center;
  }
}