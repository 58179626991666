.partner_page {
  width: 80%;
  display: grid;
  justify-content: center;
}

.partner_page h1 {
  font-size: 22px;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  font-family: "Organetto Regular", sans-serif;
  padding-bottom: 5%;
}
.partner_page h2 {
  font-weight: 700;
  font-family: "Organetto", sans-serif;
  line-height: 35px;
  padding-bottom: 5%;
}

.partner_page ul {
  padding-bottom: 3%;
  line-height: 30px;
}

.partner_page li {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 35px;
  padding-top: 5px;
}

.btn_submit {
  cursor: pointer;
  margin-bottom: 40px;
}

.btn_submit:hover {
  transform: scale(1.05) translateY(-2px);
}

.custom-toast {
  font-size: 20px;
  text-align: center;
  background-color: #28a745;
  color: white;
  padding: 20px;
  border-radius: 10px;
}

.Toastify__toast-container {
  width: auto;
  max-width: 600px;
}


@media (max-width: 768px) {
  .btn_submit:hover {
    transform: scale(1) translateY(0px);
  }
  .btn_submit:active {
    background: #282727
  }
  .partner_page {
    width: 90%;
    display: ruby;
    justify-content: center;
  }
  .partner_page span {
    font-size: 18px;
  }
  form button:hover {
    background-color: black;
    box-shadow: 0px 0px 0px 0px black;
    transform: scale(1) translateY(1px);
  }
}