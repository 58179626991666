.logo {
  display: flex;
  align-items: center;
  height:  51px;
  padding: 5px 15px 5px 15px;
  border-radius: 50px;
  border: 1px solid #000000;
  margin-left: 5%;
  text-decoration: none;
  outline: none;
}

.logo-link {
  font-family: Organetto, sans-serif;
  display: flex;
  text-decoration: none;
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 30px;
  font-weight: 800;
  line-height: 40.59px;
  text-align: center;
  color: #1A1A1A;
  margin-left: auto;
  margin-right: auto;
  outline: none;
}

.logo:hover {
  box-shadow: 0px 0px 30px -8px black;
}

@media (max-width: 768px) {
  .logo {
    display: flex;
    align-items: center;
    height:  51px;
    padding: 5px 15px 5px 15px;
    border-radius: 50px;
    border: 1px solid #000000;
    margin-left: 5%;
    text-decoration: none;
  }
  .logo-link {
    display: flex;
    text-decoration: none;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 30px;
    font-weight: 800;
    line-height: 40.59px;
    text-align: center;
    color: #1A1A1A;
  }
}




