
.navbar {
  width: 100%;
  height: 108px;
  background: transparent;
  display: flex;
  justify-content: space-around;
}

.container{
  height: 100%;
  display: flex;
  justify-content: space-around;
  width: 90%;
  align-items: center;
}

.btn-about {
  width: 100px;
  height: 32px;
  border: 1px solid #000000;
  border-radius: 50px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}

.btn-about a {
  font-size: 16px;
  font-family: Organetto, sans-serif;
  font-weight: 700;
  line-height: 21.65px;
  text-align: center;
  color: #1A1A1A;
  text-decoration: none;
}
.btn-about:hover {
  box-shadow: 0px 0px 30px -8px black;
}

@media (max-width: 1422px) {

  .dropdown_menu {
    display: flex;
  }
}

@media (min-width: 1084px) {
    .btn-about a {
      font-size: 16px;
    }
}


@media (max-width: 912px) {
  .container {
    width: 100%;
  }
  .logo {
    margin-left: 8px;
  }
  .btn-about {
    font-size: 14px;
  }
}


@media (min-width: 769px) {
  .icon-button, .full-screen-menu, .full-screen-search {
    display: none;
  }
}

@media (max-width: 768px) {
  .logo:hover,
  .admin_btn:hover,
  .icon-button:hover,
  .menu-button:hover,
  .close-icon:hover,
  .search-icon:hover,
  .menu-icon:hover {
    box-shadow: initial;
  }
  .admin_btn,
  .icon-button,
  .menu-button,
  .close-icon,
  .search-icon,
  .menu-icon,
  .logo {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
  .admin_btn:active,
  .icon-button:active,
  .menu-button:active,
  .close-icon:active,
  .search-icon:active,
  .menu-icon:active,
  .logo:active,
  {
    background-color: #f0f0f0;
  }
  .logo {
    margin-left: 15px;
  }
  .navbar {
    height: 151px;
  }

  .container {
    justify-content: space-between;
    width: 100%;
  }

  .menu-items, .contact-container, .dropdown_menu, .btn-about, .search-container {
    display: none;
  }

  .search-icon {
    display: block;
  }

  .menu-icon {
    display: block;
  }

  .hide {
    display: none;
  }
  .icons {
    display: flex;
    gap: 10px;
    margin-right: 15px;
  }

  .icon-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .icon {
    width: 24px;
    height: 24px;
  }


  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .full-screen-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .fade-enter {
    opacity: 0;
    transform: scale(0.9);
  }

  .fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  .fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  .fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  .menu-content {
    display: grid;
    position: absolute;
    top: 13%;
    left: 3%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    color: white;
  }

  .menu-button {
    background: none;
    border: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
  }

  .mobile-search {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    background: red;
    z-index: 1001;
  }
  /* Убираем стандартные стили для всех браузеров */
  .menu-content select {
    background: transparent;
    border: none; /* Убираем границу */
    color: white;
    font-family: Organetto, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.47px;
    text-align: left;
    -webkit-appearance: none; /* Убираем стили по умолчанию в Safari */
    -moz-appearance: none; /* Убираем стили по умолчанию в Firefox */
    appearance: none; /* Убираем стили по умолчанию в других браузерах */
    padding-right: 24px; /* Добавляем правый отступ для стрелочки */
  }

  /* Убираем границу у контейнера */
  .mobile-dropdown-menu {
    display: inline-block;
    position: relative;
    border: none; /* Убираем границу у контейнера */
    padding: 0; /* Убираем отступы, если есть */
  }

  /* Убираем границу у элемента option */
  .menu-content option {
    background-color: rgba(0, 0, 0, 0.9);
    border: none; /* Убираем границу у option */
  }

  /* Кастомная стрелочка */
  .mobile-dropdown-menu::after {
    content: '▼';
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    pointer-events: none;
    color: white;
  }

  /* Переопределяем стили при фокусе на элементе select */
  .menu-content select:focus,
  .menu-content select:focus-visible {
    outline: none; /* Убираем стандартный контур */
    border: none; /* Убираем границу при фокусе */
  }

  .mobile-about {
    text-decoration: none;
    font-family: Organetto, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.47px;
    text-align: left;
    color: white;
  }
  .full-screen-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
  }
}
