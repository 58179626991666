.footer {
  display: flex;
  width: 100vw;
  height: 300px;
  background: #1A1A1A;
  margin-top: 5%;
  align-items: center;
}
.footer-body {
  background: #1A1A1A;
  margin-top: 10%;
}
.footer_p {
  color: #cccccc;
  font-size: 10px;
  text-align: center;
  margin-bottom: 15px;
}

.footer_logo {
  color: #393939;
  font-family: Organetto, sans-serif;
  margin-left: 15%;
  font-size: 70px;
}
.footer_categories {
  font-family: "Organetto Regular",  sans-serif;
  font-size: 16px;
  font-weight: 300;
  gap: 2px;
  text-align: left;
  color: #FFFFFF;
  margin-left: 15%;
  margin-top: 8px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.footer_partner {
  height: 87px;
  line-height: 162px;
  margin-left: 15%;
  margin-right: auto;
  min-width: 212px;
}
.footer_partner a, .footer_categories a {
  color: #F0F0F0;
  text-decoration: none;
}

@media (max-width: 768px) {
  .footer_partner a:active, .footer_categories:active {
    color: #767676;
  }
  .footer {
    width: 100%;
    height: 80vh;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
  }
  .footer_logo {
    height: 100px;
    line-height: 100px;
    margin-top: 80px;
  }
  .footer_partner, .footer_categories, .footer_logo {
    margin-left: 0;
  }
  .footer_partner {
    line-height: 0;
    text-align: center;
    margin-top: 30px;
    width: 100%;
    margin-bottom: -50px;
  }
  .footer_partner, .footer_categories {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

  .footer_categories {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    margin-top: 8%;
  }
}