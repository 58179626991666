.login_page {
  width: 60%;
  height: 500px;
  margin: auto;
  display: grid;
  justify-content: center;
  font-family: Organetto, sans-serif;
}

.login_input {
  display: grid;
}

.login_input input {
  margin: 2%;
  height: 32px;
  align-items: center;
  border: 1px solid #000000;
  padding: 5px 10px 5px 10px;
  gap: 10px;
  border-radius: 50px;
}

.login_input button {
  margin: 2%;
  font-family: Organetto, sans-serif;
  align-items: center;
  border: 1px solid #000000;
  background: #1A1A1A;
  gap: 10px;
  border-radius: 50px;
  cursor: pointer;
  height: 52px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  color: #F0F0F0;
}

.login_input button:hover {
  border-radius: 50px;
  border: 2px solid black;
  box-shadow: 0px 0px 13px 7px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .login_page {
    width: 100%;
  }
  .login_page h1 {
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 150px;
  }
}
