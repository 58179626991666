.add_person-body {
  width: 100%;
}
.add_person {
  font-family: "Montserrat", sans-serif;
  width: 400px;
  display: grid;
}

.add_person_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
 .add_person-form {
   width: 70%;
   display: flex;
   margin: auto;
   margin-bottom: 2%;
   font-family: "Organetto", sans-serif;
   font-size: 40px;
   font-weight: 700;
   line-height: 36px;
   text-align: left;
}

.form_container {
  display: flex;
  justify-content: center;
}

.form_container form {
  width: 70%;
}

.add_person input {
  border-top: none;
  border-left: none;
  border-right: none;
}

.add_person{
 > input:focus-visible {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;
  }
}

.add_person_container textarea {
  width: 45%;
  margin-left: 10%;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}

.add_person_network-container {
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  width: 57%;
  margin-top: 4px;
}
.add_person-regions-networks {
  display: flex;
  justify-content: space-between;
}
.add_person_network {
  width: 70%;
  margin-top: 40px;
  display: grid;
  font-family: 'Montserrat', sans-serif;
}
.add_person_network_form {
  display: flex;
  flex-direction: column;
}

.add_person_network-btn {
  height: 40px;
  font-family: "Montserrat", sans-serif;
  padding: 5px 10px 5px 10px;
  gap: 10px;
  border-radius: 50px;
  color: #F0F0F0;
  cursor: pointer;
}

.add_person_network input {
  height: 80%;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-right: 5%;
}

.add_person_select {
  margin-top: 4%;
  width: 30%;
  cursor: pointer;
}
.add_person_select select {
  font-family: "Montserrat", sans-serif;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-left: 5%;
  cursor: pointer;
}

.btn_submit{
  font-family: "Montserrat", sans-serif;
  width: 247px;
  height: 50px;
  padding: 15px 30px 15px 30px;
  border-radius: 50px;
  background: #1A1A1A;
  color: #F0F0F0;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  margin-top: 5%;
}

.ad_prices_container {
  margin-top: 5%;
  font-family: "Organetto Regular", sans-serif ;

}
.ad_prices_grid {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 10px;
}

.ad_price_item {
  font-family: "Montserrat", sans-serif;
  margin-top: 3%;

}
.ad_price_item input {
  width: 60%;
  padding: 10px;
  margin-left: 0;
}

@media (max-width: 912px) {
  .add_person-form {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .add_person-form h1 {
    width: 100%;
    text-align: center;
  }
    .add_person-regions-networks {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .add_person_network-container {
    width: 100%;
  }
  .form_container form {
    width: 90%;
  }
  .add_person_container {
    display: flex;
    width: 100%;
  }
  .add_person_container textarea {
    margin-left: 0;
  }
  .add_person_select {
    width: 40%;
  }
  .ad_prices_container {
    display: grid;
    margin-top: 5%;
    justify-content: start;
    align-content: center;
  }
}

@media (max-width: 768px) {
  .ad_price_item label input {
    width: 30%;
  }
  .add_person-body {
    width: 100%;
    margin-top: 50px;
  }
  .add_person-form h1 {
    font-size: 22px;
    text-align: center;
    font-family: "Organetto Regular", sans-serif;
    margin-bottom: 20px;
  }
  .add_person input {
   font-size: 12px;
  }
  .add_person {
    width: 100%;
    margin-top: 40px;
  }
  .add_person_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .add_person_container textarea {
    width: 95%;
    margin: 10px;
  }
  .form_container form {
    width: 100%;
    display: grid;
    justify-items: center;
  }
  .form_container {
    width: 100%;
  }
  .add_person-regions-networks {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-content: space-around;
  }
  .add_person_select {
    width: 90%;
    height: 40px;
    display: flex;
    margin: 10px;
    cursor: pointer;
    justify-content: space-between;
    gap: 10px;
  }
  .add_person_select select {
    margin-left: 0;
  }
  .add_person_network {
    width: 100%;
  }
  .add_person_network span {
    text-align: center;
    font-family: "Organetto Regular", sans-serif;
  }
  .add_person_network-container {
    display: flex;
    width: 100%;
    margin-top: 4px;
    flex-direction: column;
    align-items: center;
  }
  .add_person_network_form {
    margin: 10px;
    gap: 10px;
    flex-direction: column;
  }
  .add_person_network input {
    margin-left: 0px;
  }
  .add_person_network-btn {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .ad_prices_container {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ad_price_item {
    width: 100%;
  }
  .ad_price_item input {
    width: 30%;
  }
  .ad_price_item label {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
  }
}