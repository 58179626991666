.cards-container {
  width: 100%;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  display: grid;
  justify-content: center;
}
.cards {
  border-radius: 10px;
  display: flex;
  margin-bottom: 3%;
  gap: 30px;
  justify-content: center;
}
.show-more_btn {
  text-align: center;
}
.title-cards {
  font-family: Organetto, sans-serif;
  font-size: 40px;
  font-weight: 700;
  unicode-bidi: isolate;
  margin-bottom: 60px;
  margin-top: 40px;
}
.category-section {
  display: flow;
  justify-content: left;
  margin-bottom: 100px;
}

.show-more {
  padding: 5px;
  margin-top: 40px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #cccccc;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.show-more:hover {
  transform: scale(1) translateY(0px);
  border: 1px solid black;
}

.more_persons_btn {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  color: black;
  text-decoration: none;
}

@media (max-width: 912px) {
  .cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    flex-wrap: wrap;
  }
}

@media (max-width: 820px) {
  .title-cards {
    margin-left: 50px;
  }
}

@media (max-width: 768px) {
  .cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    gap: 40px;
  }

  .category-section {
    margin-bottom: 100px;
  }

  .title-cards {
      font-family: Organetto, sans-serif;
      font-size: 30px;
      font-weight: 700;
      line-height: 28.8px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
  }
  .cards-container {
    margin-top: 20px;
  }
}