
.search-container {
  position: relative;
  width: 20%;
  height: 32px;
  line-height: 32px;
}

.search-input:hover  {
  box-shadow: 0px 0px 30px -8px black;
}

.search-input {
  width: calc(100% - 32px);
  font-family: 'Montserrat', sans-serif;
  height: 32px;
  padding: 6px 12px;
  margin-top: -1.7%;
  font-size: 16px;
  border-radius: 50px;
  border: 1px solid #1A1A1A;
}

.search-icon {
  position: absolute;
  top: 53%;
  right: 10%;
  transform: translateY(-50%);
  padding: 6px;
  cursor: pointer;
}


.search-results {
  position: absolute;
  top: 136%;
  left: 11px;
  right: 0;
  z-index: 1000;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  max-height: 200px;
  overflow-y: auto;
}

.search-result-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.search-result-item:last-child {
  border-bottom: none;
  color: #1A1A1A;
  text-decoration: none;
}

.no-results {
  padding: 10px;
  color: #999;
}

.search-link {
  text-decoration: none;
}

.highlighted {
  background-color: #eaeaea;
}