.categories_page {
  .category_name {
    font-family: Organetto, sans-serif;
    font-size: 24px;
    margin-bottom: 20px;
    margin-left: 1%;
  }

  .categories_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
.categories_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

@media (max-width: 912px) {
  .categories_page .categories_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}


@media (max-width: 768px) {
  .categories_page {
    .category_name {
      font-size: 20px;
      text-align: center;
      margin-left: 0;
    }

    .categories_container {
      grid-template-columns: 1fr;
      gap: 10px;
      padding: 0 10px;
    }
  }
}
